<template>
  <v-container class="container" fluid>
    <v-card flat class="mb-3">
      <v-btn
        small
        class="elevation-4"
        color="primary"
        v-on:click="add_click()"
        >{{ $t("add_button") }}</v-btn
      >
    </v-card>
    <!-- filter panel -->
    <GsDataFilter
      ref="dataFilter"
      :appSetting="appSettingKey"
      :filters="filters"
      v-on:changed="onFilterChanged"
    />
    <!-- table -->
    <GsDataTable
      ref="table"
      api="idp_api_url"
      endpoint="/data-stores"
      :appSetting="appSettingKey"
      :headers="headers"
      :default-sort-by="['name']"
      :default-sort-desc="[false]"
      :beforeCallApi="beforeCallApi"
      :afterCallApi="afterCallApi"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      item-key="dataStoreId"
      show-expand
      @click:row="rowClicked"
      @after-refresh="afterRefresh"
    >
      <template v-slot:item.createdAt="{ item }">
        <span
          >{{ item.createdAt | getDisplayDate }} ({{
            item.createdBy.name | empty
          }})</span
        >
      </template>
      <template v-slot:item.updatedAt="{ item }">
        <span v-if="item.updatedBy.name"
          >{{ item.updatedAt | getDisplayDate }} ({{
            item.updatedBy.name | empty
          }})</span
        >
      </template>
      <template v-slot:item.actions="{ item }">
        <GsActionsMenu
          :actions="actions"
          :onActivate="actionId => action_activate(actionId, item)"
        />
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <GsDataStoreDetails :dataStore="item" />
        </td>
      </template>
    </GsDataTable>
  </v-container>
</template>

<script>
const { callAPI } = require("ngt-frontend-core").apiOpsBff;
import { GsDataTable, GsActionsMenu, GsDataFilter } from "ngt-frontend-core";
import GsDataStoreDetails from "@/components/GsDataStoreDetails";

export default {
  name: "DataStores",
  components: {
    GsDataTable,
    GsActionsMenu,
    GsDataFilter,
    GsDataStoreDetails
  },
  props: {},
  data() {
    return {
      appSettingKey: "dataStores",
      items: [],
      expanded: [],
      singleExpand: true,
      initialized: false,
      actions: ["attributes", "tags", "edit", "delete"],
      filters: [
        {
          field: "name",
          component: "GsTextFilter",
          params: {}
        },
        {
          field: "type",
          component: "GsSelectFilter",
          params: {
            options: ["stream", "event"]
          }
        },
        {
          field: "dataStoreId",
          component: "GsIdFilter",
          params: {}
        }
      ]
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("actions"),
          value: "actions",
          width: "5%",
          sortable: false
        },
        {
          text: this.$t("name"),
          align: "left",
          value: "name",
          width: "10%",
          sortable: true
        },
        {
          text: this.$t("type"),
          align: "left",
          value: "type",
          width: "10%",
          sortable: true
        },
        {
          text: this.$t("url"),
          align: "left",
          value: "url",
          width: "15%",
          sortable: false
        },
        {
          text: this.$t("createdAt"),
          value: "createdAt",
          width: "10%",
          sortable: true
        },
        {
          text: this.$t("updatedAt"),
          value: "updatedAt",
          width: "10%",
          sortable: true
        },
        { text: "", value: "data-table-expand" }
      ];
    }
  },
  mounted() {
    this.initialized = false;
    this.$nextTick(() => {
      this.initialized = true;
    });
  },
  methods: {
    async refreshTable() {
      try {
        await this.$refs.table.refreshTable();
      } catch (error) {
        this.errorSnackbar(error);
      }
    },
    async beforeCallApi(params) {
      params.url += this.$refs.dataFilter.getFilter();
      const fields =
        "name,type,url,createdAt,updatedAt,ownedBy,createdBy,updatedBy,dataStoreId,_uri";
      params.url += `&fields=${fields}`;
      params.url += "&r8sFields=createdBy.name,ownedBy.name,updatedBy.name";
      return params;
    },
    async afterCallApi(params) {
      return params;
    },
    afterRefresh({ items }) {
      const expandedId = this.loadExpanded();
      if (expandedId) {
        const expandItem = items.find(p => p.dataStoreId == expandedId);
        if (expandItem) {
          this.expand(expandItem);
        }
      }
    },
    onFilterChanged() {
      this.$refs.table.goToFirstPage();
    },
    async add_click() {
      this.$router.push_safe(`/data-stores/new`);
    },
    async edit_click(item) {
      this.expand(item);
      this.$router.push_safe(`/data-stores/${item.dataStoreId}`);
    },
    async attributes_click(item) {
      this.expand(item);
      this.$router.push_safe(`/data-stores/${item.dataStoreId}/attrs`);
    },
    async tags_click(item) {
      this.expand(item);
      this.$router.push_safe(`/data-stores/${item.dataStoreId}/tags`);
    },
    async delete_click(item) {
      const confirmResult = await this.$dialog.confirm({
        title: this.$t("confirmation"),
        text: this.$t("delete_modal") + " : " + item.name,
        actions: {
          false: {
            text: this.$t("no"),
            color: "primary"
          },
          true: {
            text: this.$t("yes"),
            color: "default"
          }
        }
      });
      if (!confirmResult) return;

      try {
        const url = `${this.$store.state.idp_api_url}/data-stores/${item.dataStoreId}`;
        await callAPI({ url, method: "DELETE" });
        await this.refreshTable();
      } catch (error) {
        this.errorSnackbar(error);
      }
    },
    async action_activate(actionId, item) {
      switch (actionId) {
        case "attributes":
          this.attributes_click(item);
          break;
        case "tags":
          this.tags_click(item);
          break;
        case "edit":
          this.edit_click(item);
          break;
        case "delete":
          this.delete_click(item);
          break;
      }
    },
    rowClicked(item) {
      const earlyReturn = this.expanded.some(
        p => p.dataStoreId === item.dataStoreId
      );
      if (earlyReturn) {
        this.expanded = [];
        this.saveExpanded(null);
        return;
      }
      this.expand(item);
    },
    expand(item) {
      if (!this.$refs.table) return;
      if (item) {
        this.expanded = [item];
        this.saveExpanded(item.dataStoreId);
        this.identity = item;
      }
    }
  }
};
</script>
