<template>
  <v-container class="pa-0 ma-0" fluid>
    <div class="d-flex" v-if="dataStore && !loading">
      <div style="width: 50%">
        <!-- <v-card-title>Data Store:</v-card-title> -->
        <v-list-item>
          <v-list-item-content>
            <v-container fluid>
              <v-row>
                <v-col class="col-sm-3">
                  <b>{{ $t("id") }}:</b>
                </v-col>
                <v-col>{{ dataStore.dataStoreId }}</v-col>
              </v-row>

              <v-row>
                <v-col class="col-sm-3">
                  <b>{{ $t("name") }}:</b>
                </v-col>
                <v-col>{{ dataStore.name }}</v-col>
              </v-row>

              <v-row>
                <v-col class="col-sm-3">
                  <b>{{ $t("type") }}:</b>
                </v-col>
                <v-col>{{ dataStore.type }}</v-col>
              </v-row>

              <v-row>
                <v-col class="col-sm-3">
                  <b>{{ $t("url") }}:</b>
                </v-col>
                <v-col>{{ dataStore.url }}</v-col>
              </v-row>
            </v-container>
          </v-list-item-content>
        </v-list-item>
      </div>
      <div style="width: 50%">
        <!-- <v-card-title>&nbsp;</v-card-title> -->
        <v-list-item>
          <v-list-item-content>
            <v-container fluid>
              <v-row>
                <v-col class="col-sm-3">
                  <b>{{ $t("createdAt") }}:</b>
                </v-col>
                <v-col>{{ dataStore.createdAt | getDisplayDate }}</v-col>
              </v-row>

              <v-row>
                <v-col class="col-sm-3">
                  <b>{{ $t("updatedAt") }}:</b>
                </v-col>
                <v-col>{{ dataStore.updatedAt | getDisplayDate }}</v-col>
              </v-row>
            </v-container>
          </v-list-item-content>
        </v-list-item>
        <v-card-title>Attributes:</v-card-title>
        <GsAttributesDisplay
          v-if="dataStore"
          :entityId="dataStore.dataStoreId"
          entityName="data-stores"
          collection="dataStore"
        />
        <v-card-title>Tags:</v-card-title>
        <GsTagsDisplay
          v-if="dataStore"
          :id="dataStore.dataStoreId"
          endpoint="/data-stores"
          collection="dataStore"
          tagType="dp"
        />
      </div>
    </div>
    <div v-if="!dataStore && !loading">
      <span>{{ $t("no_data") }}</span>
    </div>
    <div class="text-center" v-if="loading">
      <v-progress-circular
        indeterminate
        color="primary"
        class="ma-4"
      ></v-progress-circular>
      <span>{{ $t("loading") }}</span>
    </div>
  </v-container>
</template>

<script>
import GsAttributesDisplay from "@/components/AttributeEditor/GsAttributesDisplay";
import GsTagsDisplay from "@/components/AttributeEditor/GsTagsDisplay";

export default {
  name: "GsDataStoreDetails",
  components: {
    GsAttributesDisplay,
    GsTagsDisplay
  },
  props: {
    dataStore: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      // scopes: [],
      loading: false
    };
  },
  created: async function() {},
  watch: {},
  methods: {}
};
</script>
